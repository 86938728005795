import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import Main from './components/Main/Main';
import Symbol from './components/Symbol/Symbol';

import FirebaseAPI from './services/firebaseApi'
const Telegram = window.Telegram?.WebApp?.initDataUnsafe?.user ? window.Telegram.WebApp.initDataUnsafe : {user:{id:'98765'}};

function App() {
  const firebaseAPI = new FirebaseAPI;
  // const Telegram = {user:{id:'98765'}};

  // favourites
  const [favouritesList, setFavouritesList] = useState([{},{},{},{},{},]);
  const setFavouritesList_ = ( list ) => setFavouritesList( list );

  // history
  const [historyList, setHistoryList] = useState([{},{},{},{},{}]);
  const setHistoryList_ = ( list ) => setHistoryList( list );

  useEffect(() => {
    firebaseAPI.listenUserList(Telegram.user.id, setFavouritesList_, setHistoryList_);
  }, []
  );



  return (
    <>
      <Routes>
        <Route 
            path="/" 
            element={
              <Main telegram={Telegram} favouritesList={favouritesList} historyList={historyList}/>
            }
          />

        <Route 
          path="/symbol/:symbolId"
          element={
            <Symbol 
              telegram={Telegram} 
              favouritesList={favouritesList}
            />
          } 
        />

        <Route 
            path="*" 
            element={
              <Main telegram={Telegram} favouritesList={favouritesList} historyList={historyList}/>
            }
          />
      </Routes>
    </>
  );
}

export default App;
