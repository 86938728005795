import React, { useState , useEffect, useRef} from 'react';
import {Link, useMatch } from "react-router-dom";

import {CssBaseline, Grid, Typography, Container,  Skeleton, Chip, Box } from '@mui/material';
import {Avatar, Card, CardContent, CardHeader, IconButton, CardActions, Collapse} from '@mui/material';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { styled } from '@mui/material/styles';

import { createChart, ColorType } from 'lightweight-charts';

import TwelveData from '../../services/dataApi';
import FirebaseAPI from '../../services/firebaseApi'
const twelveData = new TwelveData;
const firebaseAPI = new FirebaseAPI;

const format = (str) => {
    const round00 = ( num ) => ( Math.floor(num * 100) / 100 );
    if (+str > 1000000000000){
        return round00(+str/1000000000000) + 'T';
    } else if (+str > 1000000000){
        return round00(+str/1000000000) + 'B';
    } else if (+str > 1000000){
        return round00(+str/1000000) + 'M';
    } else if (+str > 1000){
        return round00(+str/1000) + 'K';
    } else {
        return round00(+str);
    }
}

const dateToTimestamp = ( strDate = "2020-05-12T23:50:21.817Z" ) => {
    const date = new Date(strDate);
    return date.getTime()/1000;
}


const ChartComponent = props => {
	const {
		data,
        seriesType,
		colors: {
			backgroundColor = 'white',
			lineColor = '#2962FF',
			textColor = 'black',
			areaTopColor = '#2962FF',
			areaBottomColor = 'rgba(41, 98, 255, 0.28)',
		},
	} = props;
	const chartContainerRef = useRef();

	useEffect(
		() => {
			const handleResize = () => {
				chart.applyOptions({ width: chartContainerRef.current.clientWidth });
			};

			const chart = createChart(chartContainerRef.current, {
				layout: {
					background: { type: ColorType.Solid, color: backgroundColor },
					textColor,
				},
				width: chartContainerRef.current.clientWidth,
				height: 300,
			});
			chart.timeScale().fitContent();
			chart.timeScale().applyOptions({timeVisible:true});

            let newSeries = null;
            if (seriesType=="Line"){
                newSeries = chart.addLineSeries({ lineColor, topColor: areaTopColor, bottomColor: areaBottomColor });
            } else {
                newSeries = chart.addCandlestickSeries({ lineColor, topColor: areaTopColor, bottomColor: areaBottomColor });
            }
            newSeries.setData(data);


			window.addEventListener('resize', handleResize);

			return () => {
				window.removeEventListener('resize', handleResize);

				chart.remove();
			};
		},
		[data, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]
	);

	return (
		<div
			ref={chartContainerRef}
		/>
	);
};




const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));


function Statistics({symbol}){
    const [symbolStatistics, setSymbolStatistics] = useState({});

    useEffect( () =>
        {
            twelveData.getSymbolStatistics(symbol)
            .then( json => {
                setSymbolStatistics( json );
            });
        }, []
    );

    const StatisticsUI = (
        <Card sx={{my:2}}>
            <CardContent>
                <Typography variant="h5" component="div">
                    Fundamentals
                </Typography>
                <Grid container xs={12}>
                    <Grid xs={6} sx={{my:1}}>
                        <Box sx={{fontSize: '0.8em'}}>Cap</Box>
                        { symbolStatistics.statistics ? format(+symbolStatistics.statistics.valuations_metrics.market_capitalization) : <Skeleton variant="text" sx={{width:"50px"}}/> }
                    </Grid>

                    <Grid xs={6} sx={{my:1}}>
                        <Box sx={{fontSize: '0.8em'}}>Enterprise value</Box>
                        { symbolStatistics.statistics ? format(+symbolStatistics.statistics.valuations_metrics.enterprise_value) : <Skeleton variant="text" sx={{width:"50px"}}/> }
                    </Grid>

                    <Grid xs={6} sx={{my:1}}>
                        <Box sx={{fontSize: '0.8em'}}>P/E</Box>
                        { symbolStatistics.statistics ? format(+symbolStatistics.statistics.valuations_metrics.trailing_pe) : <Skeleton variant="text" sx={{width:"50px"}}/> }
                    </Grid>

                    <Grid xs={6} sx={{my:1}}>
                        <Box sx={{fontSize: '0.8em'}}>P/S</Box>
                        { symbolStatistics.statistics ? format(+symbolStatistics.statistics.valuations_metrics.price_to_sales_ttm) : <Skeleton variant="text" sx={{width:"50px"}}/> }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )

    return symbolStatistics ? StatisticsUI : ''
}

function Profile({symbol}){
    const [symbolProfile, setSymbolProfile] = useState({});
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    
    useEffect( () =>
        {   // profile with caching 
            firebaseAPI.getSymbolProfile( symbol, setSymbolProfile, twelveData.getSymbolProfile, firebaseAPI.setSymbolProfile )
        }, []
    );

    const profileUI = (<Card sx={{my:1}}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: 'grey' }} aria-label="recipe">
                                        {symbolProfile.name ? symbolProfile.name[0] : <Skeleton variant="text" sx={{width:'20%'}}/>}
                                    </Avatar>
                                }
                                action={
                                    <ExpandMore
                                        expand={expanded}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                        >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                    }
                                title={symbolProfile.name ? symbolProfile.name : <Skeleton variant="text" sx={{width:'80%'}}/>}
                                subheader={symbolProfile.sector ? `${symbolProfile.sector}, ${symbolProfile.industry}` : <Skeleton variant="text" sx={{width:'50%'}}/>}
                            />
                            <CardContent>
                                <Typography>
                                    {symbolProfile.description ? symbolProfile.description.substring(0, symbolProfile.description.indexOf('. ', 100)) : <><Skeleton variant="text"/><Skeleton variant="text"/><Skeleton variant="text"/></>}
                                </Typography>
                            </CardContent>
                            
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <Typography paragraph>About {symbolProfile.name}</Typography>
                                    {symbolProfile.description ? symbolProfile.description : <><Skeleton variant="text"/><Skeleton variant="text"/><Skeleton variant="text"/></>}
                                </CardContent>
                                <CardActions disableSpacing>
                                    <ExpandMore
                                        expand={expanded}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                        >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                </CardActions>
                            </Collapse>
                        </Card>);
    
    return symbolProfile ? profileUI : ''
}




export default function Symbol( { telegram, favouritesList } ) {
    const symbol = useMatch("/symbol/:symbolId").params.symbolId;
    const [symbolQuote, setSymbolQuote] = useState( {symbol: symbol});
    
    const dataSeriesPeriods = ['D', 'W', 'M', 'Y'];
    const [dataSeriesPeriod, setDataSeriesPeriod] = useState('D');
    
    const dataSeriesTypes = ['Candlestick', 'Line' ];
    const [dataSeriesType, setDataSeriesType] = useState('Candlestick');


    const [dataSeries, setDataSeries] = useState([]);


    useEffect( () =>
        {
            // series
            twelveData.getSymbolTimeSeries(symbol, dataSeriesPeriod)
            .then( json => {
                setDataSeries( json.values.reverse().map( tick => (
                    {
                        time: dateToTimestamp( tick.datetime ), 
                        value: format( tick.close ),
                        open: tick.open, 
                        high: tick.high, 
                        low: tick.low, 
                        close: tick.close
                    }
                    ))
                );
            });
        },
        [dataSeriesPeriod, dataSeriesType]
    );


    useEffect( () =>
        {
            document.title = symbol + ' - Better Call Buffett';
            
            // quote
            twelveData.getSymbolQuote(symbol)
            .then( json => {
                json.color = +json.percent_change>0 ? 'green' : 'red';
                json.currencyChar = json.currency=='USD' ? '$' : json.currency;
                document.title = json.name + ' - Better Call Buffett';
                setSymbolQuote( json );
            });

            // add to history
            firebaseAPI.addToHistory(symbol, telegram.user.id);
        },
        []
    );

    const addToFavourites = (symbol, telegramUserId) => firebaseAPI.addToFavourites(symbol, telegramUserId)
    const removeFromFavourites = (symbol, telegramUserId) => firebaseAPI.removeFromFavourites(symbol, telegramUserId)
    

    const favouritesUI = favouritesList.some( item => (item.symbol == symbol) ) ? <BookmarkIcon onClick={()=>{removeFromFavourites(symbol, telegram.user.id)}} fontSize="large" /> : <BookmarkBorderIcon onClick={()=>{addToFavourites(symbol, telegram.user.id )}} fontSize="large" />

    const dataSeriesPeriodsUI = dataSeriesPeriods.map ( period => {
        return ( <Chip label={period} sx={{ml:1}}  color={ period==dataSeriesPeriod ? 'primary' : 'default'} onClick={() => setDataSeriesPeriod(period)}/> )
    })

    const dataSeriesTypesUI = dataSeriesTypes.map ( type => {
        return ( <Chip label={type} sx={{mr:1}}  color={ type==dataSeriesType ? 'primary' : 'default'} onClick={() => setDataSeriesType(type)}/> )
    })


    return (
        <React.Fragment>
            <CssBaseline />

            {/* header */}
            <Container maxWidth="sm" sx={{my:2}}>
                <Grid container>
                    <Grid xs={1}>
                        <Link to="/">
                            <ArrowBackIcon fontSize="large"  />
                        </Link>
                    </Grid>
                    <Grid xs={10} sx={{textAlign: 'center', my:1}}>
                            {symbolQuote.name ? symbolQuote.name : <Skeleton variant="text" sx={{ width: '50%', margin: '0 auto' }} />}
                    </Grid>
                    <Grid xs={1}>
                        { favouritesUI }
                    </Grid>
                </Grid>
                
            </Container>

            {/* ticker, price change */}
            <Container maxWidth="sm"  sx={{my:1}}>
                <Grid container sx={{fontSize: '1.6em'}}>
                    <Grid xs={9} container>
                        <Grid>
                            {symbolQuote.symbol}
                        </Grid>
                        <Grid sx={{mx:2,color:symbolQuote.color}}>
                            {symbolQuote.percent_change ? (Math.floor(symbolQuote.percent_change * 100) / 100)+'%' : <Skeleton variant="text" sx={{width:'70px'}} />}
                        </Grid>
                    </Grid>
                    <Grid xs={3} sx={{textAlign:'end', color:symbolQuote.color}}>
                            {symbolQuote.close ? (symbolQuote.currencyChar + format(symbolQuote.close)) : <Skeleton variant="text" sx={{width:'70px',textAlign:'end'}} />}
                    </Grid>
                </Grid>        
            </Container>

            {/* chart */}
            <Container maxWidth="sm" sx={{p:0, mt: 1}}>
                <ChartComponent colors={{}} data={dataSeries} seriesType={dataSeriesType}></ChartComponent>
            </Container>
            
            {/* chart buttons */}
            <Container maxWidth="sm" >
                <Grid container sx={{mt: 1}}>
                    <Grid xs={6}>
                        { dataSeriesTypesUI }
                    </Grid>
                    <Grid xs={6} sx={{textAlign: 'end'}}>
                        { dataSeriesPeriodsUI }
                    </Grid>
                </Grid>
            </Container>
            
            {/* detail cards */}
            <Container maxWidth="sm" sx={{my:1, pt: 1,  backgroundColor: '#eeeeee'}}>
                <Profile symbol={symbol} />
                <Statistics symbol={symbol} />
                
                <Card sx={{my:2}}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Statistics
                        </Typography>
                        <Grid container xs={12}>
                            <Grid xs={6} sx={{my:1}}>
                                <Box sx={{fontSize: '0.8em'}}>Open</Box>
                                {format(+symbolQuote.open)}
                            </Grid>

                            <Grid xs={6} sx={{my:1}}>
                                <Box sx={{fontSize: '0.8em'}}>Volume</Box>
                                {format(+symbolQuote.volume)}

                            </Grid>
                            
                            <Grid xs={6} sx={{my:1}}>
                                <Box sx={{fontSize: '0.8em'}}>Today's high</Box>
                                {format(+symbolQuote.high)}
                            </Grid>

                            <Grid xs={6} sx={{my:1}}>
                                <Box sx={{fontSize: '0.8em'}}>Today's low</Box>
                                {format(+symbolQuote.low)}
                            </Grid>
                            
                            <Grid xs={6} sx={{my:1}}>
                                <Box sx={{fontSize: '0.8em'}}>52 Week High</Box>
                                {format(+symbolQuote.fifty_two_week?.high)}
                            </Grid>
                            
                            <Grid xs={6} sx={{my:1}}>
                                <Box sx={{fontSize: '0.8em'}}>52 Week Low</Box>
                                {format(+symbolQuote.fifty_two_week?.low)}
                            </Grid>

                            <Grid xs={6} sx={{my:1}}>
                                <Box sx={{fontSize: '0.8em'}}>Average volume</Box>
                                {format(+symbolQuote.average_volume)}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Container>

        </React.Fragment>
    );
}