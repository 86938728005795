import firebase from "firebase/app";
import "firebase/firestore";
 

const firebaseConfig = {
    apiKey: "AIzaSyCswEbg_Zr8EmgDkkLinaLxUqCuohfa1kU",
    authDomain: "quotes-ac3af.firebaseapp.com",
    projectId: "quotes-ac3af",
    storageBucket: "quotes-ac3af.appspot.com",
    messagingSenderId: "368125605345",
    appId: "1:368125605345:web:023b5f5db595a95e490ea0",
    measurementId: "G-Q5JZ0DET8T"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();


class FirebaseAPI {

    getRedditList = async () => {
        let redditList = [];

        await db.collection("reddit-list").get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                redditList.push( {symbol: doc.data().ticker} );
            });
        });
        return redditList;
    }

    getSymbolProfile = async (symbol, setSymbolProfile, getSymbolProfileTwelveData, setSymbolProfileFirebase) => {
        await db.collection("symbol-profile").doc(symbol).get()
        .then((doc) => {
            console.log('getSymbolProfile', doc.data());
            if (doc.exists) {
                setSymbolProfile(doc.data())
            } else {
                console.log('getSymbolProfileTwelveData');
                getSymbolProfileTwelveData(symbol)
                .then( json => {
                    console.log('setSymbolProfileFirebase', json);
                    if (json.status != 'error'){
                        setSymbolProfileFirebase(symbol, json);
                        setSymbolProfile( json )
                    } else {
                        setSymbolProfile( false )
                    }
                });
            }
        });
    }

    setSymbolProfile = async (symbol, json) => {
        console.log('setSymbolProfile',json);
        await db.collection("symbol-profile").doc(symbol).set(json)
        .catch((error) => {
            console.error("Error setSymbolProfile: ", error);
        });
    }

    addToHistory = (symbol, telegramUserId) => {
        db.collection("user-lists").doc(`${telegramUserId}`).collection("history").doc(symbol)
        .set({timestamp: firebase.firestore.FieldValue.serverTimestamp()})
        .catch((error) => {
            console.error("Error addToHistory: ", error);
        });
    }

    addToFavourites = (symbol, telegramUserId) => {
        db.collection("user-lists").doc(`${telegramUserId}`).collection("favourites").doc(symbol)
        .set({})
        .catch((error) => {
            console.error("Error addToFavourites: ", error);
        });
    }
    
    removeFromFavourites = (symbol, telegramUserId) => {
        db.collection("user-lists").doc(`${telegramUserId}`).collection("favourites").doc(symbol)
        .delete()
        .catch((error) => {
            console.error("Error removeFromFavourites: ", error);
        });
    }
    
    listenUserList = async (telegramUserId, setFavouritesList, setHistoryList ) => {
        db.collection("user-lists").doc(`${telegramUserId}`).collection("favourites")
        .onSnapshot( (querySnapshot) => {
            let list = [];
            querySnapshot.forEach((doc) => {
                list.push( {symbol: doc.id} );
            });
            setFavouritesList(list);
        });

        db.collection("user-lists").doc(`${telegramUserId}`).collection("history").orderBy("timestamp", "desc").limit(15)
        .onSnapshot( (querySnapshot_) => {
            let list = [];
            querySnapshot_.forEach((doc) => {
                list.push( {symbol: doc.id} );
            });
            setHistoryList(list);
        });

    }
    
}

export default FirebaseAPI;

