import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {CssBaseline, TextField, Autocomplete, Container,  Chip, Skeleton, Box} from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

import TwelveData from '../../services/dataApi';



function Main( {telegram, favouritesList, historyList} ) {
    const [value, setValue] = useState(null);
    const [options, setOptions] = useState([]);
    const [searchOptions, setSearchOptions] = useState([]);
    
    

    const twelveData = new TwelveData;

    function getData (searchQuery){
        if (searchQuery.length > 2){
            twelveData.getSymbolSearch(searchQuery)
            .then( arr => {
                setSearchOptions( arr);
            });
        }
    }

    const buffettsListUI = [
        {symbol:'BRK.B'},
        {symbol:'AAPL'},
        {symbol:'BAC'},
        {symbol:'CVX'},
        {symbol:'KO'},
        {symbol:'OXY'},
        {symbol:'MCO'},
        {symbol:'ATVI'},
        {symbol:'TSM'},
    ].map( item => {  
        return (
            <Link to={"symbol/"+item.symbol} style={{color: 'black', textDecoration: 'none' }}>
                <Chip 
                    label={item.symbol ? item.symbol : <Skeleton variant="text" sx={{ width: '40px' }}/>} 
                    sx={{mr:1, mb:1}} 
                    href={"symbol/"+item.symbol}/>
            </Link>
        )
    });

    const favouritesListUI = favouritesList.length > 0 ? favouritesList.map( item => {  
        return (
            <Link to={"symbol/"+item.symbol} style={{color: 'black', textDecoration: 'none' }}>
                <Chip 
                    label={item.symbol ? item.symbol : <Skeleton variant="text" sx={{ width: '40px' }}/>} 
                    sx={{mr:1, mb:1}}/>
            </Link>
        )
    }) : <>Tap <BookmarkBorderIcon fontSize="small" sx={{mb:-0.8}}/> on the stocks screen to add it here</>;

    const historyListUI = historyList.length > 0 ? historyList.map( item => { 
        return (
            <Link to={"symbol/"+item.symbol} style={{color: 'black', textDecoration: 'none' }}>
                <Chip 
                    label={item.symbol ? item.symbol : <Skeleton variant="text" sx={{ width: '35px' }}/>} 
                    sx={{mr:1, mb:1}}/>
            </Link>
        );
    }) : <>All visited stocks are adding here</>;

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="sm" sx={{p:1}}>
                    <Autocomplete
                        freeSolo
                        id="combo-box-demo"
                        filterOptions={(x) => x}
                        options={searchOptions}
                        noOptionsText='Start typing...'
                        sx={{ mt: 2, width: '100%' }}
                        renderInput={(params) => <TextField {...params}  size="small" label="Search stocks by name or ticker" />}
                        value={value}
                        onChange={(event, newValue) => {
                            setOptions(newValue ? [newValue, ...options] : options);
                            setValue(newValue);
                        }}
                        getOptionLabel={(option) =>
                            typeof option === 'string' ? option : option.instrument_name
                          }
                        renderOption={(props, option) => (
                            <div style={{ width: '100%'}} {...props}>
                                <Link to={"/symbol/"+option.symbol} style={{color: 'black', textDecoration: 'none', width: '100%' }}>
                                    <Box sx={{ display: 'grid', gridAutoColumns: '1fr', gap: 1, mb: 1 }}>
                                        <Box sx={{ gridRow: '1', gridColumn: 'span 2' }}>
                                            {option.instrument_name}
                                        </Box>
                                        <Box sx={{ gridRow: '1', gridColumn: '4 / 5', textAlign: 'end' }}>
                                            {option.symbol}
                                        </Box>
                                    </Box>
                                </Link>

                            </div>
                   
                            
                          )}
                        onInputChange={ (e, searchQuery) => getData( searchQuery ) }
                        />
            </Container>

            <Container maxWidth="sm" sx={{my:3}}>
                <h2>My List</h2>
                {favouritesListUI}
            </Container>

            <Container maxWidth="sm" sx={{my:3}}>
                <h2>Buffett's choice</h2>
                {buffettsListUI}
            </Container>


            <Container maxWidth="sm" sx={{my:3}}>
                <h2>My History</h2>
                {historyListUI}
            </Container>

        </React.Fragment>
    );
}

export default Main;
